import { FC } from 'react';
import {
  Pending,
  Failure,
  Nominated,
  Unsent,
  Success,
  TransmissionFailed,
  NominatedAcknowledged,
  AcknowError,
  MessageCreated,
  MappingError,
  SavedIcon,
  ZeroNominationStatusIcon,
  MatchedIcon,
  CircleCheckmarkIconWithWarn,
} from 'assets/icons';
import { MessageStatus } from 'utils/types';

interface StatusIndicatorProps {
  status: MessageStatus;
}
// TODO: Rename the icons to match status
export const StatusIndicator: FC<StatusIndicatorProps> = ({ status }) => {
  switch (status) {
    case 'ZERO_NOMINATION':
      return <ZeroNominationStatusIcon title="0-Nomination" />;
    case 'SAVED':
      return <SavedIcon title="Edited - Initial nomination pending" />;
    case 'SAVED_RENOMINATION':
      return <SavedIcon title="Edited – Renomination pending" />;
    case 'SAVED_EXCLUDED':
      return <SavedIcon title="Edited" />;
    case 'UNSENT':
      return <MessageCreated title="Nominated - Message created" />;
    case 'PENDING':
      return <Pending title="Nominated - Transmission pending" />;
    case 'SENT':
      return <Nominated title="Nominated - Awaiting response" />;
    case 'ACKNOWLEDGED':
      return <NominatedAcknowledged title="Nominated - Acknowledged" />;
    case 'ACKNOWLEDGED_ERROR':
      return <AcknowError title="Nominated - Acknowledged with error" />;
    case 'MISMATCHED':
      return <Failure title="Nominated - Mismatched" />;
    case 'MISMATCHED_PARTIAL':
      return <Failure title="Mismatched partial" />;
    case 'NOMINATED':
      return <MatchedIcon title="Nominated - Matched" />;
    case 'FAILURE':
      return <TransmissionFailed title="Failure" />;
    case 'RECEIVED':
      return <Success title="Received" />;
    case 'RECEIVED_PROCESSED_ERROR':
      return <MappingError title="Mapping error" />;
    case 'WARNING':
      return <CircleCheckmarkIconWithWarn title="Nominated - Lesser Rule Matching" />;
    default:
      return <Unsent title="Undefined" />;
  }
};
