export enum MessageStatus {
  SAVED = 'SAVED',
  ZERO_NOMINATION = 'ZERO_NOMINATION',
  SAVED_RENOMINATION = 'SAVED_RENOMINATION',
  SAVED_EXCLUDED = 'SAVED_EXCLUDED',
  UNSENT = 'UNSENT',
  PENDING = 'PENDING',
  SENT = 'SENT',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  ACKNOWLEDGED_ERROR = 'ACKNOWLEDGED_ERROR',
  MISMATCHED = 'MISMATCHED',
  MISMATCHED_PARTIAL = 'MISMATCHED_PARTIAL',
  NOMINATED = 'NOMINATED',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  WARNING = 'WARNING',

  RECEIVED = 'RECEIVED',
  RECEIVED_PROCESSED = 'RECEIVED_PROCESSED',
  RECEIVED_PROCESSED_ERROR = 'RECEIVED_PROCESSED_ERROR',
}

export enum NotificationType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
}

export interface StatusOptions {
  unsent: boolean;
  saved: boolean;
  saved_renomination: boolean;
  pending: boolean;
  sent: boolean;
  acknowledged: boolean;
  acknowledged_error: boolean;
  mismatched: boolean;
  mismatched_partial: boolean;
  nominated: boolean;
  success: boolean;
  failure: boolean;
  received: boolean;
  received_processed: boolean;
  received_processed_error: boolean;
}

export interface HourlyValues {
  timetable: number[];
}

export interface HourlyStatus {
  timetable: Array<MessageStatus>;
}

export interface OriginTimeSeriesType {
  type: string;
  title: string;
  definition: string;
}

export interface ReplyMessageStatus {
  code: string;
  definition: string;
  title: string;
}
export interface Values {
  originTimeSeriesType: OriginTimeSeriesType;
  hourlyValues: HourlyValues;
  hourlyStatus: HourlyStatus;
  replyMessageHourlyStatusDto?: {
    timetable: Array<Array<ReplyMessageStatus>>;
  };
}

export interface TimeSeriesMessageValuesDto {
  nominationTrackId: number;
  connectionPointName: string;
  destinationId: number;
  externalAccount: string;
  externalHourlyValues: Array<Values>;
  internalHourlyValues: Values;
  messageStatus: MessageStatus;
  sourceId: number;
  version: number;
  excludeInOutgoingMessage: boolean;
  externalAccountCounterPartyName: string;
  externalAccountCounterPartyCode: string;
  gasDayHourDefinition: GasDayHourDefinitionType;
}

interface MessageConnectionPointType {
  code: string;
  id: number;
}

export type MessageDirection = 'SENT' | 'RECEIVED';

export interface MessageDetailsGroupType {
  connectionPointName: string;
  internalAccount: string;

  timeSeriesMessageDtos: Array<TimeSeriesMessageValuesDto>;
}

// Todo: Merge this type with MessageDetails type
export interface Message {
  sendTime: string;
  creationTime: string;
  documentName: string;
  documentType: string;
  ediGasVersion: string;
  messageDefinitionDescription: string;
  documentIdentifier: string;
  documentVersion: string;
  contractReference: string;
  internalAccountName: string;
  direction: MessageDirection;
  gasDate: string;
  id: number;
  recipient: string;
  sender: string;
  issuerMarketParticipant: string;
  recipientMarketParticipant: string;
  messageStatus: MessageStatus;
  valuesStructured: Array<MessageDetailsGroupType>;
  connectionPointName: MessageConnectionPointType;
  replyMessages: Array<Message>;
  replyMessageErrors: Array<string>;
  replyMessageWarnings: Array<string>;
}

export interface ConnectionPointMessageType {
  code: string;
  id: number;
}

export interface Identifier {
  id?: number;
  identifier: string;
  type: IdentifierType;
  validFrom: string;
  validTo: string;
}

export interface IdentifierType {
  id: number;
  name: string;
  scheme: string;
}

export interface RoleType {
  code: string;
  id: number;
  role: string;
}

export interface Role {
  id?: number;
  role: RoleType;
  validFrom: string;
  validTo: string;
}

export type CustomSelectOptions = Record<string, unknown>;
export interface SelectOptionType extends CustomSelectOptions {
  id: number;
  name?: string;
  description?: string;
  code?: string;
  role?: string;
  release?: number;
  channelName?: string;
  counterparty?: {
    code: string;
  };
}

export type CountryCode =
  | 'DE'
  | 'FR'
  | 'IT'
  | 'CH'
  | 'AT'
  | 'NL'
  | 'BE'
  | 'DK'
  | 'GB'
  | 'ES'
  | 'PL'
  | 'CZ'
  | 'SK'
  | 'SI'
  | 'HU'
  | 'BG'
  | 'RO'
  | 'LV'
  | 'LT'
  | 'EE'
  | 'FI'
  | 'NO'
  | 'PT';

export interface Country {
  id: number;
  isoAlpha2Code: CountryCode;
  name: string;
}

export type SortDirection = 'asc' | 'desc' | 'none';

export interface SortType {
  name: string;
  direction: SortDirection;
}

export interface TimePeriodType {
  from: string;
  to: string;
}

export interface GasDayHourDefinitionType {
  date: string;
  firstTimeStamp: string;
  gasDayOffsetHours: number;
  lastTimeStamp: string;
  localTimeStrings: Array<TimePeriodType>;
  numberOfHours: number;
  utcDateTimes: Array<TimePeriodType>;
  zoneId: string;
  zonedDateTimes: Array<TimePeriodType>;
}
export interface MainCustomErrorType {
  code?: string;
  message?: string;
  errors: Array<CustomErrorType>;
}
export interface CustomErrorType {
  code: string;
  field: string;
  message: string;
  timeStamp: string;
  value: string;
  errors?: Array<CustomErrorType>;
}

export type EditMode = 'OVERWRITE' | 'DONT_OVERWRITE' | 'DISCARD_CHANGES';

export type SettingsWindowType = 'adminNotification' | 'notification' | 'account';

export type FormOption = 'edit' | 'create';
